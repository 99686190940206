import $ from "jquery";
import { isCompExists, logFactory, components } from "GlobalSite";

const compName = "comp_aem_layout_footer";
const Log = logFactory(compName);
const { log } = Log;

const {
  compTechGenericModal: { openDialog },
} = components;

log("--->");

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    Log.log(`${compName} inexistant ... js associé non exécuté`);

    return;
  }

  const $trigger = $("#trigger-popin-cookie-parameter");
  const $cookiesParameters = $("#cookiesparameters");

  $cookiesParameters.on("click", function (event) {
    event.preventDefault();

    Log.log("click pour déclencher le paramétrage des cookies");

    $trigger.trigger("click");
  });

  const $CTDialog = $("#contractTermination");
  $(document).on("click", "#resiliation-contrat-popin", function (event) {
    event.preventDefault();
    openDialog($CTDialog);
  });
});
